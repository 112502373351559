import React, { useState, useEffect, useMemo, useRef } from "react"
import Select from "react-select"
import Pagination from "../Pagination/Pagination"
import Index from "./Index"
import Button from "./Button"
import Statistics from "./statistics"
import Loader from "../Loader/Loader"

import "./Indexes.scss"

const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)

const Indexes = ({ dataUrl, title, language }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [pageSize, setPageSize] = useState(25)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortType, setSortType] = useState()

  const ref = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      await fetch(`${dataUrl}`)
        .then(response => {
          if (response.ok) {
            return response
          }
          throw Error(response.status)
        })
        .then(response => response.json())
        .then(data => {
          setData(data.results)
          setLoading(false)
        })
        .catch(error => console.log(error))
    }
    fetchData()
  }, [dataUrl])

  useEffect(() => {
    scrollToRef(ref)
  }, [currentPage])

  useEffect(() => {
    let sortIndexes = [...data]
    const sortArray = () => {
      if (sortType === "AZ") {
        sortIndexes.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        setData(sortIndexes)
      } else if (sortType === "ZA") {
        sortIndexes.sort((a, b) =>
          b.name.toLowerCase().localeCompare(a.name.toLowerCase())
        )
        setData(sortIndexes)
      }
    }
    sortArray()
  }, [sortType])

  const currentIndexes = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize
    return data.slice(firstPageIndex, lastPageIndex)
  }, [currentPage][sortType])

  const handleSelectChange = selectedOption => {
    setPageSize(parseFloat(selectedOption.value))
  }

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      position: "static",
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "white" : state.isFocused ? "white" : "black",
      backgroundColor: state.isSelected
        ? "#8d1227"
        : state.isFocused
        ? "#263238"
        : "white",
      padding: 8,
      fontSize: "16px",
    }),
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      width: 170,
      fontSize: "16px",
      borderColor: state.isFocused ? "#c8ad59" : "#a8a8a8",
      borderRadius: "7px 0px",
      height: 29,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = "opacity 300ms"
      return { ...provided, opacity, transition }
    },
  }

  const options = [
    { value: "50", label: "50" },
    { value: "75", label: "75" },
    { value: "100", label: "100" },
    { value: "200", label: "200" },
  ]
  return (
    <div className="c-Indexes">
      <span className="c-Indexes__label">{title}</span>
      <div className="c-Indexes__wrapper c-Indexes__wrapper--narrow" ref={ref}>
        {loading ? (
          <Loader additionClass="c-Indexes__loader" />
        ) : (
          <>
            <div className="c-Indexes__options">
              <Button
                text="Sortuj A&nbsp;-&nbsp;Z"
                click={() => setSortType("AZ")}
                disabled={sortType === "AZ"}
              />
              <Button
                text="Sortuj Z&nbsp;-&nbsp;A"
                click={() => setSortType("ZA")}
                disabled={sortType === "ZA"}
              />

              <Select
                className="c-Indexes__select"
                options={options}
                defaultValue={pageSize}
                onChange={handleSelectChange}
                placeholder={"Liczba wyników"}
                styles={customStyles}
              />
            </div>
            <ul className="c-Indexes__itemList">
              {currentIndexes.map((indexItem, index) => (
                <li key={index} className="c-Indexes__item">
                  <Index
                    wordIndex={indexItem.name}
                    wordIndexId={indexItem.id}
                    word={indexItem.word}
                    wordId={indexItem.word_id}
                    language={language}
                  />
                </li>
              ))}
            </ul>
            <Statistics text={data.length} />
            <Pagination
              className="c-Indexes__pagination"
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={pageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default Indexes
