import * as React from "react"
import { API_INDEXES_GR } from "../global/api"

import LayoutSubpage from "../layouts/layout_subpage"
import Indexes from "../components/Indexes/Indexes"

const IndexPage = () => (
  <LayoutSubpage title="Indeks odpowiedników greckich">
    <Indexes dataUrl={API_INDEXES_GR} title="Indeks odpowiedników greckich" />
  </LayoutSubpage>
)

export default IndexPage
